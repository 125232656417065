import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
        // return new Promise((resolve, reject) => {
        //     BaseService(param)
        //         .then((response) => {
        //             resolve(response)
        //         })
        //         .catch((errors) => {
        //             reject(errors)
        //         })
        // })

        return BaseService(param);
    },
}

export default ApiService
