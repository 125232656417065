const appConfig = {
    apiPrefix: 'https://evoting-staging.fptech.dev/api',
    userEntryPath: '/user-dashboard',
    adminEntryPath: '/admin-dashboard',
    AuthenticatedEntryPath:'/home',
    unAuthenticatedEntryPath: '/user-sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
